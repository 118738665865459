import { Box } from "@kaizen/component-library"
import { Login } from "../../Form/Login"
import { setCookie } from "cookies-next"
import Image from "next/image"
import React, { useEffect } from "react"
import classNames from "classnames"
import loginIllustration from "../../Image/login-illustration.svg"
import styles from "./Main.module.scss"

interface Props {
  central?: boolean
}

const redirectSubstring = "redirect="

const checkForDeepLinkCacheRedirectVal = () => {
  const redirectIndex = window.location.href.indexOf(redirectSubstring)
  if (redirectIndex !== -1) {
    const redirectValue = window.location.href.substring(
      redirectIndex + redirectSubstring.length,
    )
    cookieSetter(redirectValue)
  }
}

const cookieSetter = (cookieVal: string) => {
  const fiveMinutes = 5 * 60 * 1000 // 5 minutes in milliseconds

  // Set the cookie with the desired name, value, and options
  setCookie("redirect_to", cookieVal, {
    expires: new Date(Date.now() + fiveMinutes),
    path: "/",
  })
}

export const Main: React.FC<Props> = ({ central = false }) => {
  useEffect(() => checkForDeepLinkCacheRedirectVal(), [])

  return (
    <Box
      classNameOverride={classNames(styles.main, central ? styles.central : {})}
    >
      <Box classNameOverride={styles.illustration}>
        <Image
          src={loginIllustration}
          height={614}
          width={2068}
          alt=""
          className={styles.illustrationImage}
          priority={true}
        />
      </Box>
      <Box classNameOverride={styles.form} mb={0.25}>
        <Login />
      </Box>
    </Box>
  )
}
