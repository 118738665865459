/* eslint-disable @calm/react-intl/missing-formatted-message */
import { AriaLiveErrorMessage } from "../../AriaLive/AriaLiveErrorMessage"
import { Box } from "@kaizen/component-library"
import { Button } from "@kaizen/button"
import { Card } from "@kaizen/draft-card"
import { GoogleSignIn } from "../../Button/GoogleSignIn"
import { InlineNotification } from "@kaizen/notification"
import { SignInParams } from "../../../types/Login/account-login-form"
import { Text } from "@kaizen/components"
import { TextField } from "@kaizen/draft-form/KaizenDraft/Form/TextField/TextField"
import { VisuallyHidden } from "@kaizen/a11y"
import { getCookie } from "cookies-next"
import {
  getMurmurResetPasswordUrl,
  getMurmurSSOUrl,
  isText,
} from "../../../utils"
import { signInErrorToastNotification } from "../../Notification/ToastNotification"
import { useCsrfTokenContext } from "../../../providers"
import Link from "next/link"
import React, { useEffect, useRef, useState } from "react"
import email from "@kaizen/component-library/icons/email.icon.svg"
import permissions from "@kaizen/component-library/icons/permissions.icon.svg"
import styles from "../Form.module.scss"

export interface Props {
  availableLoginOptions: string[]
  workEmail: string
  applicationId: string
  subdomain: string
  displayUnrecognisedLoginError: boolean
  unrecognisedLoginErrorCount: number
  handleGoBack: () => void
}

export const AccountLoginForm: React.FC<Props> = ({
  availableLoginOptions,
  workEmail,
  applicationId,
  subdomain,
  displayUnrecognisedLoginError,
  unrecognisedLoginErrorCount,
  handleGoBack,
}) => {
  const { csrfToken, isLoading } = useCsrfTokenContext()

  const [showPasswordSignIn, setShowPasswordSignIn] = useState<boolean>(false)
  const [showGoogleSignIn, setShowGoogleSignIn] = useState<boolean>(true)
  const [showSamlSignIn, setShowSamlSignIn] = useState<boolean>(false)
  const [password, setPassword] = useState<string>("")
  const [showUnrecognisedLoginError, setShowUnrecognisedLoginError] =
    useState<boolean>(displayUnrecognisedLoginError)
  const [unrecognisedLoginErrorCounter, setUnrecognisedLoginErrorCounter] =
    useState<number>(unrecognisedLoginErrorCount)
  const [resetPasswordUrl, setResetPasswordUrl] = useState<string>("")
  const [ssoUrl, setSSOUrl] = useState<string>("")
  const [showInvalidPasswordError, setShowInvalidPasswordError] =
    useState<boolean>(false)
  const [invalidPasswordErrorCounter, setInvalidPasswordErrorCounter] =
    useState<number>(0)
  const [signInButtonDisabled, setSignInButtonDisabled] =
    useState<boolean>(false)

  const textFieldRef = useRef<HTMLInputElement | null>(null)
  const notificationRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    if (showUnrecognisedLoginError && notificationRef.current) {
      notificationRef.current.focus()
    }
  }, [showUnrecognisedLoginError])

  useEffect(() => {
    setResetPasswordUrl(
      getMurmurResetPasswordUrl(
        window.location.protocol,
        window.location.hostname,
      ),
    )
  }, [])

  useEffect(() => {
    setSSOUrl(
      getMurmurSSOUrl(
        window.location.protocol,
        window.location.hostname,
        subdomain,
        getCookie("redirect_to") as string,
      ),
    )
  }, [subdomain])

  useEffect(() => {
    setShowPasswordSignIn(availableLoginOptions.includes("email"))
    setShowGoogleSignIn(availableLoginOptions.includes("googleOauth"))
    setShowSamlSignIn(availableLoginOptions.includes("saml"))
  }, [availableLoginOptions])

  const isGoogleButtonPrimary = (availableLoginOptions: string[]) => {
    if (
      availableLoginOptions.length === 1 &&
      availableLoginOptions.includes("googleOauth")
    ) {
      return true
    }

    return (
      availableLoginOptions.length === 2 &&
      availableLoginOptions.includes("googleOauth") &&
      availableLoginOptions.includes("saml")
    )
  }

  const isSamlButtonPrimary = (availableLoginOptions: string[]) => {
    return (
      availableLoginOptions.length === 1 &&
      availableLoginOptions.includes("saml")
    )
  }

  const unrecognisedLoginSetter = (
    value: boolean,
    setShowUnrecognisedLoginError: React.Dispatch<
      React.SetStateAction<boolean>
    >,
  ) => {
    setShowUnrecognisedLoginError(value)
  }

  const handleSignIn = (signInParams: SignInParams) => async () => {
    const {
      setShowUnrecognisedLoginError,
      subdomain,
      applicationId,
      workEmail,
      password,
      setUnrecognisedLoginErrorCounter,
      textFieldRef,
      csrfToken,
      setShowInvalidPasswordError,
      setInvalidPasswordErrorCounter,
    } = signInParams

    setSignInButtonDisabled(true)

    unrecognisedLoginSetter(false, setShowUnrecognisedLoginError)
    setShowInvalidPasswordError(false)

    if (!isText(password)) {
      setShowInvalidPasswordError(true)
      setInvalidPasswordErrorCounter(prevCounter => prevCounter + 1)
      textFieldRef.current?.focus()
      setSignInButtonDisabled(false)
      return
    }

    const response = await fetch("/app/auth/api/password-login", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        applicationId: applicationId,
        loginId: workEmail,
        password: password,
        subdomain: subdomain,
        csrfToken,
      }),
    }).then(response => {
      unrecognisedLoginSetter(false, setShowUnrecognisedLoginError)
      // we decided to return 404 for unauthorized requests to avoid leaking information
      if (response.status == 404) {
        setUnrecognisedLoginErrorCounter(prevCounter => prevCounter + 1)
        unrecognisedLoginSetter(true, setShowUnrecognisedLoginError)
      } else if (response.status == 500) {
        signInErrorToastNotification()
        textFieldRef.current?.focus()
      }

      return response.json()
    })

    if (response.status === "success") {
      window.location.href = `${response.data.redirectUrl}`
    }
    setSignInButtonDisabled(false) //
  }

  if (isLoading || !csrfToken) {
    return null
  }

  const signInParams: SignInParams = {
    setShowUnrecognisedLoginError,
    subdomain,
    applicationId,
    workEmail,
    password,
    setUnrecognisedLoginErrorCounter,
    textFieldRef,
    csrfToken,
    setShowInvalidPasswordError,
    setInvalidPasswordErrorCounter,
  }

  return (
    <>
      <Card classNameOverride={styles.form}>
        <Box p={1}>
          {showUnrecognisedLoginError && (
            <div tabIndex={-1} ref={notificationRef}>
              <InlineNotification
                title="We don’t recognise that login"
                type="negative"
                persistent
                hideCloseIcon
              >
                Check your login details and try again.{" "}
                <Link
                  href={{
                    pathname: resetPasswordUrl,
                  }}
                  legacyBehavior={false}
                  aria-label="Can't sign in? Recover your account here."
                >
                  Need help signing in?
                </Link>
                <VisuallyHidden>
                  {unrecognisedLoginErrorCounter > 0 && (
                    <AriaLiveErrorMessage
                      counter={unrecognisedLoginErrorCounter}
                      message="We don’t recognise that login. Check your login details and try again."
                    />
                  )}
                </VisuallyHidden>
              </InlineNotification>
            </div>
          )}

          <Box mt={0.25} mb={0.75}>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid*/}
            <a
              href="#"
              onClick={handleGoBack}
              className={styles.topLink}
              aria-label="Go back to choose a different email"
            >
              ← Choose a different email
            </a>
          </Box>

          <Box mb={0.5}>
            <dl>
              <dt>Work email</dt>
              <dd>
                <Text variant="body">{workEmail}</Text>
              </dd>
            </dl>
          </Box>

          {showPasswordSignIn && (
            <>
              <TextField
                id="password"
                inputRef={textFieldRef}
                inputType="password"
                labelText="Password"
                icon={permissions}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setPassword(event.target.value)
                }}
                onKeyDown={event => {
                  if (event.key === "Enter") {
                    event.preventDefault()
                    handleSignIn(signInParams)()
                  }
                }}
                // eslint-disable-next-line jsx-a11y/no-autofocus
                autoFocus
                status={showInvalidPasswordError ? "error" : "default"}
                validationMessage={
                  showInvalidPasswordError
                    ? "Please enter a valid password"
                    : ""
                }
              />

              <VisuallyHidden>
                {invalidPasswordErrorCounter > 0 && (
                  <AriaLiveErrorMessage
                    counter={invalidPasswordErrorCounter}
                    message="Please enter a valid password"
                  />
                )}
              </VisuallyHidden>

              <Box mb={0.5}>
                <Button
                  label="Sign In"
                  aria-label="Sign in with email"
                  primary
                  fullWidth
                  onClick={handleSignIn(signInParams)}
                  disabled={signInButtonDisabled}
                />
              </Box>
            </>
          )}

          {showGoogleSignIn && (
            <GoogleSignIn
              isPrimary={isGoogleButtonPrimary(availableLoginOptions)}
            />
          )}

          {showSamlSignIn && (
            <>
              <Box mb={0.5}>
                <Button
                  label="Sign in with SSO"
                  primary={isSamlButtonPrimary(availableLoginOptions)}
                  fullWidth
                  href={ssoUrl}
                />
              </Box>
            </>
          )}

          <Box p={0.25}>
            <Link
              href={{
                pathname: resetPasswordUrl,
              }}
              legacyBehavior={false}
              className={styles.bottomLink}
              aria-label="Can't sign in? Recover your account here."
            >
              Can't sign in?
            </Link>
          </Box>
        </Box>
      </Card>
    </>
  )
}
